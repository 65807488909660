<template>
  <div>
    <h1>Edit Header :: {{ id }}</h1>
  </div>
</template>

<script>
export default {
  name: "adminHeaderEdit",
  props : {
    id : {
      type : String
    }
  }
}
</script>
<style scoped>

</style>